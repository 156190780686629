import React, { useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Logo from "../../assets/images/brit_logo.png";
import CopyIcon from "../../assets/images/ico_copy_footer.svg";
import { useAppDispatch } from "../../store";
import { showPrivacy } from "../common/popup/popupSlice";
import PrivacyPolicy from "../common/popup/PrivacyPolicy";

const FooterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: 116px;
  z-index: 500;
  /* background: #157efb; */

  @media screen and (max-width: 768px) {
    height: auto;
    padding-bottom: 20px;
  }
`;

const FooterContainerWrap = styled.div`
  display: flex;
  max-width: 1650px;
  justify-content: space-between;
  /* align-items: center; */
  /* background-color: #6638f0; */
  margin: 0 auto;
  padding: 32px 0;

  @media screen and (max-width: 768px) {
    padding: 0;
    flex-wrap: wrap;
    position: relative;
    justify-content: flex-start;
  }
`;

const FooterLeftContainer = styled.div`
  display: flex;
  width: 60%;
  margin: 0px 50px;
  /* background-color: #b0f566; */

  @media screen and (max-width: 768px) {
    width: 95%;
    margin: 0px 10px;
    padding: 5% 0;
    flex-wrap: wrap;
  }
`;

const FooterLogoWrap = styled.div`
  display: flex;
  align-items: center;
  /* margin: 0px 0px 0px 192px; */
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 30px;
  }
`;

const FooterLogo = styled.img`
  display: flex;
  width: 82px;
  height: 36px;
  margin: 0px 60px 0px 0px;
  /* background-color: #5cc9f5; */

  @media screen and (max-width: 768px) {
    margin: 0px 0px 0px 10px;
  }
`;

const BoxInfoTop = styled.div`
  /* display: flex;
  flex-direction: column;
  align-content: center; */
  @media screen and (max-width: 768px) {
    padding: 1.5%;
  }
  @media screen and (max-width: 425px) {
    padding: 2.5%;
  }
`;

const BoxInfoList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    li {
      width: 100%;
    }
  }
`;

const TextInfo = styled.a`
  font-family: "Pretendard";
  font-size: 16px;
  color: #000;
  text-decoration: none;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 24px;
  }
`;

const TextBtn = styled.i`
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url("${CopyIcon}") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  margin: 3px 26px 0 6px;
  cursor: pointer;
`;

const BoxInfoBottom = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #a8a8a8;
  /* background-color: yellow; */

  @media screen and (max-width: 768px) {
    font-size: 11px;
    padding-top: 10px;
  }
`;

const FooterRightContainer = styled.div`
  display: inline-flex;
  margin: 0px 50px 0px 0px;
  /* background-color: #4af2a1; */

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 10px;
    right: 3%;
    margin: 0 auto;
  }
`;

const BoxInfoRule = styled.div`
  display: inline-block;
  top: 32px;
  right: 54px;
  a {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: right;
    color: #1f1f1f;
    cursor: pointer;
  }
`;

export default function Footer() {
  const outside = useRef();

  const popup = useSelector((state) => state.popup);
  const dispatch = useAppDispatch();

  const onClickAddress = () => {
    window.open(
      "https://map.naver.com/p/entry/place/18642358?c=15.00,0,0,0,dh"
    );
  };

  const copyAddress = () => {
    window.navigator.clipboard
      .writeText("서울특별시 영등포구 선유로 146, 이앤씨드림타워 610호")
      .then(() => {});
  };

  const copyEmail = () => {
    window.navigator.clipboard.writeText("info@br-it.co.kr").then(() => {});
  };

  const onClickOpen = () => {
    dispatch(showPrivacy());
  };

  return (
    <FooterWrap>
      <FooterContainerWrap>
        <FooterLeftContainer>
          <FooterLogoWrap>
            <FooterLogo src={Logo} />
          </FooterLogoWrap>
          <BoxInfoTop>
            <BoxInfoList>
              <li>
                <TextInfo onClick={onClickAddress}>
                  서울특별시 영등포구 선유로 146, 이앤씨드림타워 610호
                </TextInfo>
                <TextBtn onClick={copyAddress} />
              </li>
              <li>
                <TextInfo>info@br-it.co.kr</TextInfo>
                <TextBtn onClick={copyEmail} />
              </li>
              <BrowserView>
                <TextInfo>070-4010-2877(Fax 070-8250-2877)</TextInfo>
              </BrowserView>
              <MobileView>
                <TextInfo href="tel:070-4010-2877">
                  070-4010-2877(Fax 070-8250-2877)
                </TextInfo>
              </MobileView>
            </BoxInfoList>
            <BoxInfoBottom>
              COPYRIGHT © 2020 BRIT ALL RIGHTS RESERVED
            </BoxInfoBottom>
          </BoxInfoTop>
        </FooterLeftContainer>
        <FooterRightContainer>
          <BoxInfoRule>
            <a href="#!" title="개인정보처리방침" onClick={onClickOpen}>
              개인정보처리방침
            </a>
            {popup.privacyMode && <PrivacyPolicy ref={outside} />}
          </BoxInfoRule>
        </FooterRightContainer>
      </FooterContainerWrap>
    </FooterWrap>
  );
}
